import Layout from '../containers/layout';
import styled from 'styled-components';
import 'react-image-gallery/styles/css/image-gallery.css';
import SEO from '../components/seo';
import { ContainerBodyWidth } from '../components/ContainerBodyWidth';
import { BOOKING, EMAIL } from '../constants/content';
import { PageHeadline } from '../components/PageHeadline';
import { InfoLine } from '../components/InfoLine';
import { InfoHeadline } from '../components/InfoHeadline';

const Impressum = () => {
  const site = {
    title: BOOKING.TITLE,
    description: 'description',
    keywords: [],
  };

  return (
    <Layout currentPage="impressum">
      <SEO title={BOOKING.TITLE} description={site.description} keywords={site.keywords} />

      <ContainerBodyWidth>
        <div style={{ padding: 20 }}>
          <PageHeadline>Impressum</PageHeadline>

          <br />
          <InfoHeadline>Anbieter:</InfoHeadline>
          <InfoLine>Astrid Starke-Knecht & Andreas Knecht</InfoLine>
          <InfoLine>Am Reichenbächle 53</InfoLine>
          <InfoLine>79350 Sexau</InfoLine>
          <InfoLine>Deutschland</InfoLine>
          <br />

          <InfoHeadline>Kontakt:</InfoHeadline>
          <InfoLine>
            <a href={`mailto:${EMAIL}`} target="_blank" rel="noreferrer">
              {EMAIL}
            </a>
          </InfoLine>
          <InfoLine>
            <a href="tel:+4976419338282">+49-(0)7641 93 38 282</a>
          </InfoLine>
        </div>
      </ContainerBodyWidth>
    </Layout>
  );
};

export default Impressum;
